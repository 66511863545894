import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Images from '../img/Images'
import Menu from './menu';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			showModal: false,
			search: '',
			showSearch: false
        };
    }

    toggleModal = () => {
        this.setState({ showModal: !this.state.showModal });
		};
		
		

    renderHeader = () => {
		const { home, title } = this.props;
		const subheaderTask = home ? 'menu-subheader -home' : 'menu-subheader';

		return (
			<header
				className='header'
			>
				<a className="back-to-gov" href="https://www.aemc.gov.au" target='_blank'>{`< Back to aemc.gov.au`}</a>
				<Link
					to='/'
					className="nav-logo"
				>
					<img src={Images.logoWhite} alt="AEMC"/>
					{
						title && <h1 className='hero-title'>{title}</h1>
					}
					<p className={subheaderTask}>Retail energy competition review 2019</p>
				</Link>
				<div className="nav-button-container">
					<div className="desktop-nav-buttons">
						<button className="open-menu" aria-label="Toggle Menu" onClick={this.toggleModal}
							style={{
								background: 'none',
								border: 'none',
								marginLeft: 0
							}}
						>Menu</button>
						<p>\\</p>
						<button className="open-menu" aria-label="Toggle Search" onClick={this.toggleSearch}
							style={{
								background: 'none',
								border: 'none',
								marginRight: 0
							}}
						>Search</button>
					</div>
					<div className="mobile-nav-buttons">
						<button className="open-menu" aria-label="Toggle Search" onClick={this.toggleSearch}
							style={{
								background: 'none',
								border: 'none'
							}}
						><img className="search-logo" src={Images.search} alt="search"/></button>
						<button className="open-menu" aria-label="Toggle Menu" onClick={this.toggleModal}
							style={{
								background: 'none',
								border: 'none'
							}}
						><img className="menu-logo" src={Images.menu} alt="menu"/></button>
					</div>
				</div>
				{this.state.showModal &&
					<Menu
						home={home}
						toggleModal={this.toggleModal}
					/>
				}
			</header>
    	)
    }

    onChangeText = (e) => {
    	this.setState({search: e.target.value})
    }

    handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			this.handleSearch(e);
		}
	}

	handleSearch = (e) => {
		e.preventDefault()
		const {search} = this.state
		if(!search) return

		navigate(
          `/search?keyword=${this.state.search}`
        )
	}

    renderSearch = () => {
    	return (
    		<header
				className='header'
			>
				<div className='search-wrapper'>
					<div className='input-container'>
						<input
						 	className='search-bar'
							value={this.state.search}
							type='text'
							name='search'
							placeholder='Search...'
							onChange={this.onChangeText}
							onKeyPress={this.handleKeyPress}
						/>
						<img src={Images.search} className='search-icon' alt="AEMC"/>
					</div>
					<button
						onClick={this.toggleSearch}
						className='close-icon'
					><a className="">CLOSE</a>
					</button>
				</div>
			</header>
    	)
    }

    toggleSearch = () => {
    	this.setState({ showSearch: !this.state.showSearch });
    }

    render() {
        const { home } = this.props;
        const headClass = home ? 'home-header-wrapper' : 'header-wrapper';
        const { showSearch } = this.state;
        return (
        	<div className={headClass} >
        		<div className="centre-container -remove-padding">
							<div className="inner grid-ten -centre">
	          			{showSearch ? this.renderSearch() : this.renderHeader()}
							</div>
				  	</div>
          </div>
      	)
   }
}

Header.propTypes = {
  home: PropTypes.bool,
}

Header.defaultProps = {
  home: false,
}

export default Header
