import React from 'react';
import { StaticQuery, graphql, Link, navigate } from 'gatsby';
import Images from '../img/Images';

export default props => (
	<StaticQuery
		query={graphql`
			query JurisdictionHeadingQuery {
				jurisdictions: allDatoCmsJurisdiction(
					filter: { nationalJurisdiction: { eq: false } }
				) {
					edges {
						node {
							title
							slug
						}
					}
				},
				featureArticles: allDatoCmsFeatureArticle {
					edges {
						node {
							title
							slug
						}
					}
				},
				keyMessages: datoCmsHome {
					nationalJurisdiction {
						slug
						bodyContent {
							... on DatoCmsHeading {
								id
								keyMessage
							}
						}
					}
				}
			}
		`}

		render={data => {
			let {
				jurisdictions,
				keyMessages,
				featureArticles,
			} = data;
			jurisdictions = jurisdictions.edges;

			let keyMessagesArr = [];
			let slug = '';
			if (
				keyMessages &&
				keyMessages.nationalJurisdiction &&
				keyMessages.nationalJurisdiction.slug &&
				keyMessages.nationalJurisdiction.bodyContent
			) {
				keyMessagesArr = keyMessages.nationalJurisdiction.bodyContent.filter((obj) => {
					return obj.keyMessage
				});
				// slug = keyMessages.nationalJurisdiction.slug;
			}
			const { home } = props;
			const headClass = home ? 'home menu-modal' : 'menu-modal';
			return (
				<div className={headClass}>

				<div className="centre-container -remove-padding-top">
					<div className="inner grid-ten -centre">

						<div className="header">
							<a className="nav-logo" href="/"><img src={Images.logoWhite} alt="AEMC"/></a>

							<p className="menu-subheader">Retail energy competition review 2019</p>

							<div className="menu-button">
								<button
									onClick={props.toggleModal}
									style={{
										background: 'none',
										border: 'none'
									}}
								>
									<p className="menu-button-text">Close </p>
								</button>
							</div>
						</div>

					<div className="menu-item-container">
						<div className="column-third">
							<h3>\ Key Messages</h3>
							<ul>
								{keyMessagesArr.map((elem, i) => {
									if (elem.keyMessage) {
										return <li key={i}>
											<Link 
												to={`/#message${i+1}`}
												onClick={
													(e) => {
														props.toggleModal()
													}
												}
											>
												{`+ ${elem.keyMessage}`}
											</Link>
										</li>
									}
								})}
							</ul>
						</div>
						<div className="column-third">
						<h3>\ Feature Articles</h3>
							<ul>
							{featureArticles.edges.map((elem, i) => {
									return (
										<li key={i}>
											<Link to={`/feature-article/${elem.node.slug}`}>+ {elem.node.title}</Link>
										</li>
									);
								})}
							</ul>
						</div>
						<div className="column-third">
							<h3>\ Explore by jursidiction</h3>
							<ul>
								{jurisdictions.map((elem, i) => (
									<li key={i}>
										<Link to={`/jurisdiction/${elem.node.slug}`}>
											{`+ ${elem.node.title}`}
										</Link>
									</li>
								))}
							</ul>
							</div>
						</div>
						</div>
					</div>
				</div>
			);
		}}
	/>
);
