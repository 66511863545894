import React from 'react';
import { Line } from 'react-chartjs-2';
import Color from 'color';
import Data from '../utils/data';
import { ColorPool } from '../common/color-pool.js';
import { Decimals } from '../common/decimals.js';


const ChartSample = ({ data }) => {
	// Clone the data to work with
	let cloneData = [...data];
	cloneData = Data.cleanNumbers(cloneData);

	// Get chart title
	let chartTitle = cloneData[2][1];

	// Get x-title
	let xTitle = '';
	if (cloneData[5][0] === 'X axis') {
		xTitle = cloneData[5][1];
	}

	// Get y-title
	let yTitle = '';
	if (cloneData[6][0] === 'Y axis') {
		yTitle = cloneData[6][1];
	}

	// Set up and clean x-Labels, first select the correct column + remove first column
	let xLabels = cloneData[7].slice(1);

	// Select data area
	cloneData = cloneData.slice(8);

	// Define datasets
	let datasets = [];

	// LOOP THROUGH DATA POINTS
	cloneData.forEach((col, i) => {
		// Set colours
		let currentColor = ColorPool[i];
		let lightColor = Color(currentColor).lighten(0.2);

		// Set dataset
		let dataset = {
			label: col[0],
			data: Decimals(col.slice(1)),
			fill: false,
			backgroundColor: currentColor,
			borderColor: currentColor,
			borderWidth: 2,
			lineTension: 0,
			hoverBackgroundColor: lightColor,
			hoverBorderColor: currentColor
		};

		datasets.push(dataset);
	});

	// OPTIONS
	let options = {
		responsive: true,
		legend: {
			display: true,
			position: 'bottom'
		},
		scales: {
			yAxes: [
				{
					display: true,
					gridLines: {
						display:false
					},
					scaleLabel: {
						display: true,
						labelString: yTitle
					},
					ticks: {
						beginAtZero: true,
						// Include a dollar sign in the ticks
						callback: (value, index, values) => {
							return `${Decimals(value)}`;
						}
					}
				}
			],
			xAxes: [
				{
					display: true,
					gridLines: {
						display:false
					},
					scaleLabel: {
						display: true,
						labelString: xTitle
					}
				}
			]
		}
	};

	// SET CHART
	let chart = {
		xLabels,
		datasets
	};

	// RETURN CHART
	return (
		<div>
			<Line data={chart} options={options} height={300}/>
		</div>
	);
};

export default ChartSample;
