import React from 'react';
import { Bar } from 'react-chartjs-2';
import Color from 'color';
import Data from '../utils/data';
import { ColorPool } from '../common/color-pool.js';
import { Decimals } from '../common/decimals.js';

const ChartL = ({ data }) => {
	// console.log([...data]);
  // Clone the data to work with
	let cloneData = [...data];
	cloneData = Data.cleanNumbers(cloneData);

	// Get chart title
	let chartTitle = cloneData[2][1];

	// Get x-title
	let xTitle = '';
	if (cloneData[5][0] === 'X axis') {
		xTitle = cloneData[5][1];
	}

	// Get y-title
	let yTitle = '';
	if (cloneData[6][0] === 'Y axis') {
		yTitle = cloneData[6][1];
	}

	// Set up and clean x-Labels, first select the correct column + remove first column
	let xLabels = [];

	// Select data area
	cloneData = cloneData.slice(8);

	let datasets = [];
	let datasets2 = [];


	let newData = {};
	let key = '';
	let label = '';

	// Clean up the data
	cloneData.forEach((col, i) => {
		label = `${col[1]};${col[2]}`;
		if (xLabels.indexOf(label) == -1) {
			xLabels.push(label);
		}

		key = col[0];

		if (!(key in newData)) {
			newData[key] = [Decimals(col[3])];
		} else {
			newData[key].push(Decimals(col[3]));
		}

	});

	// create the data sets
	for (var property in newData) {
		if (newData.hasOwnProperty(property)) {
			let i = Object.keys(newData).indexOf(property);

			let currentColor = ColorPool[i];
			let lightColor = Color(currentColor).lighten(0.2);

			// Set dataset
			let dataset = {
				label: property,
				data: newData[property],
				backgroundColor: currentColor,
				borderColor: currentColor,
				borderWidth: 1,
				hoverBackgroundColor: lightColor,
				hoverBorderColor: currentColor,
				xAxisID: `x-test`
			};
			// let dataset2 = dataset;
			// dataset2[xAxisID] = 'x-text-2';
			// console.log(dataset);
			datasets.push(dataset);
			// datasets2.push(dataset2);
		}
	}

	// Set up options
	let options = {
		responsive: true,
		legend: {
			display: true,
			position: 'bottom'
		},
		scales: {
			yAxes: [
				{
					display: true,
					gridLines: {
						display:false
					},
					scaleLabel: {
						display: true,
						labelString: yTitle
					},
					ticks: {
						precision: 1,
						beginAtZero: true,
						// Include a dollar sign in the ticks
						callback: (value, index, values) => {
							return Decimals(value);
						}
					}
				}
			],
			xAxes: [
				{
					id: 'x-test',
					gridLines: {
						display:false
					},
					type: 'category',
					ticks:{
						callback: function(label) {
							let xLabelOne = label.split(';')[1];
							return xLabelOne;
						}
					}
				},
				{
					id: 'x-test2',
					gridLines: {
						display:false
					},
					type: 'category',
					gridLines: {
						display: false,
					},
					ticks:{
						callback: function(label) {
							let xLabelTwo = label;
							let xLabelOne = label.split(';')[0];
							if (label.split(';')[1] == '2015-16') {
								return xLabelOne;
							} else {
								return '';
							}
						}
					},
				}
			]
		}
	};

	let chart = {
		xLabels,
		datasets
	};

	return (
		<div>
			<Bar data={chart} options={options} height={300} />
		</div>
	);
};

export default ChartL;
