import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import Images from '../img/Images'
import DownloadBlocks from './downloadBlocks'

const Footer = ({ siteTitle }) => (
	<StaticQuery
		query={graphql`
			query JurisdictionFooterQuery {
				jurisdictions: allDatoCmsJurisdiction (
					filter: {
						nationalJurisdiction: {
							eq: false
						}
					}
				) {
					edges {
						node {
							title
							slug
						}
					}
				},
				keyMessages: datoCmsHome {
					dataPortalCopy
					nationalJurisdiction {
					slug
					bodyContent {
						... on DatoCmsHeading {
						id
							keyMessage
						}
					}
					}
				},
				featureArticles: allDatoCmsFeatureArticle {
					edges {
						node {
							title
							slug
						}
					}
				},
				social: allDatoCmsSocialProfile {
					edges {
						node {
							url
							profileType
						}
					}
				}
			}
		`}
		render={data => {
			let {
				jurisdictions,
				keyMessages,
				featureArticles,
				social
			} = data;
			jurisdictions = jurisdictions.edges;

			let keyMessagesArr = [];
			let slug = '';
			if (
				keyMessages &&
				keyMessages.nationalJurisdiction &&
				keyMessages.nationalJurisdiction.slug &&
				keyMessages.nationalJurisdiction.bodyContent
			) {
				keyMessagesArr = keyMessages.nationalJurisdiction.bodyContent.filter((obj) => {
				return obj.keyMessage
				})
			}
			return (
				<div className='footer-container'>
					<div className='footer-container-wrapper'>
					<DownloadBlocks />
					<div className='description-block'>
						<div className="centre-container">
							<div className="inner grid-ten -centre">
								<div
									className='data-portal'
									dangerouslySetInnerHTML={{
									__html: keyMessages.dataPortalCopy,
									}}
								/>

								<a href='https://www.aemc.gov.au/data/competition-review-2019' target="_blank" >CHECK IT OUT <img src={Images.topRightWhite}  alt='check out' /></a>
							</div>
						</div>
					</div>

					<div className='footer-main'>
						<div className="centre-container">
						<div
							className='inner grid-ten -centre'
						>
							<div className='f-header'>
							<Link
								to='/'
								className="nav-logo"
							>
								<img src={Images.logoWhite} alt='logo' />
							</Link>
							</div>
							<div
							className='footer-items grid-container'
							>
								<div className="footer-item">
								<p className='footer-item-header'>\ In Brief</p>
								<div className="footer-sub-container">
									{keyMessagesArr.map((elem, i) => {
										if (elem.keyMessage) {
										return <p key={i}>
											<Link to={`/#message${i+1}`} className='footer-item-sub-header'>
												{`+ ${elem.keyMessage}`}
											</Link>
										</p>
										}
									})}
								</div>
								</div>

								<div className="footer-item">
									<p className='footer-item-header'>\ Feature Articles</p>

									<div className="footer-sub-container">
									{featureArticles.edges.map((elem, i) => {
										return (
										<p key={i}>
										<Link
											to={`/feature-article/${elem.node.slug}`}
											className='footer-item-sub-header'
										>
											+ {elem.node.title}
										</Link>
										</p>
										)
									})}
									</div>
								</div>
								<div className="footer-item">
								<p className='footer-item-header'>\ Explore by Jursidiction</p>
								<div className="footer-sub-container">
									{jurisdictions.map((elem, i) => (
									<p key={i}>
										<Link
											to={`/jurisdiction/${elem.node.slug}`}
											className='footer-item-sub-header'
										>{`+ ${elem.node.title}`}
										</Link>
									</p>
									))}
								</div>
								</div>

								<div className="grid-quarter footer-item">
								<p className='footer-item-header'>\ Social</p>
								<div className="footer-sub-container">
									{social.edges.map((elem, i) => {
									return(
										<p key={i}>
										<a
											target="_blank"
											href={elem.node.url}
											className='footer-item-sub-header'
										>
											{elem.node.profileType}
										</a>
										</p>
									)
									})}
								</div>
								<p className='footer-item-header terms'>\ Terms</p>
								<div className="footer-sub-container">
									<p>
									<a
										target="_blank"
										href="https://www.aemc.gov.au/terms-use"
										className='footer-item-sub-header'
									>
										Terms of use
									</a>
									</p>
								</div>
								</div>
							</div>
						</div>
						</div>
					</div>
				</div>
				</div>
			)
		}}
	/>
)

Footer.propTypes = {
	siteTitle: PropTypes.string,
}

Footer.defaultProps = {
	siteTitle: ``,
}

export default Footer
