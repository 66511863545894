import React, { Fragment } from 'react'
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import Img from 'gatsby-image'
import Images from '../img/Images'
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from 'react-socks'
import Select, { components } from 'react-select'

class SideBarComponent extends React.Component {
    constructor(props) {
        super(props)
    }

    getHash = (title) => {
      return title
      .replace(/[^\w\s]/gi, '')
      .toLowerCase()
      .replace(/ /gi, '-');
    }

    render() {
      const { content } = this.props
      const displayContent = content.map((obj, i, arr) => {
        if (obj.keyMessage) {
          let hash = this.getHash(obj.keyMessage)
          let link = <li className='second-layer-inner' key={i}><a href={`#${hash}`} >{`- ${obj.keyMessage}` }</a></li>
          let innerArr = arr.slice(i)
          let inner = [];
          for (let k = 1; k < innerArr.length; k++) {
            if (innerArr[k].sectionTitle) {
              inner.push({
                'title': innerArr[k].sectionTitle,
                'id': innerArr[k].id,
                'hash': this.getHash(innerArr[k].sectionTitle)
              });
            }

            if (innerArr[k].__typename == 'DatoCmsHeading') {
              break
            }
          }

          if (inner.length) {
            return <li key={i} className='first-layer-inner'>
              <a href={`#${hash}`} >{`- ${obj.keyMessage}`}</a>
              <ul className='second-layer'>
                {inner.map((elem, j) => (
                  <li
                    key={j}
                    className='second-layer-inner'
                  >
                    <a href={`#${elem.hash}`} >
                      {`- ${elem.title}`}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          }
          return link;
        }
      })

	  return (
        <ul className='top-layer'>
          {displayContent}              
        </ul>  
      )
  }
}

SideBarComponent.propTypes = {
	content: PropTypes.array
}

SideBarComponent.defaultProps = {
	content: []
}

export default SideBarComponent
