import React, { Fragment } from 'react'
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"

const twURL = 'https://twitter.com/intent/tweet?via=the_aemc&text='

const linkedinURL = 'https://www.linkedin.com/shareArticle/?mini=true&url='

const fbURL = 'https://www.facebook.com/sharer/sharer.php?u='

const url = 'https://2019.aemc.gov.au/competition-review';
// const url = 'https://staging.comp-review-2019.aemc.prescoapps.co';

export const SocialLine = ({path}) => {
	let shareUrl = `${url}${path}`;
	shareUrl = shareUrl.replace('competition-review/competition-review/', 'competition-review/');

	return (
		<div>
			<p>
				<a
					href={`${twURL}${shareUrl}`}
					target='_blank'
				>
					<span >Twitter</span>
				</a>
				<a
					href={`${fbURL}${shareUrl}`}
					target='_blank'
				>
					<span >Facebook</span>
				</a>
				<a
					href={`${linkedinURL}${shareUrl}`}
					target='_blank'
				>
					<span >LinkedIn</span>
				</a>
			</p>
		</div>
	)
}

export const SocialBox = (props) => {
	let { shareId, path } = props;

	let shareUrl = `${url}${path}`;
	shareUrl = shareUrl.replace('competition-review/competition-review/', 'competition-review/');
	return (
		<div className='social_box'>
			<h5>SHARE THIS SECTION</h5>
			<div className='flex-row'>
				<a
					href={`${twURL}${shareUrl}%23${shareId}`}
					target='_blank'
				>
					<span >Tw</span>
				</a>
				<a
					href={`${fbURL}${shareUrl}%23${shareId}`}
					target='_blank'
				>
					<span >Fb</span>
				</a>
				<a
					href={`${linkedinURL}${shareUrl}#${shareId}`}
					target='_blank'
				>
					<span >Ln</span>
				</a>
			</div>
		</div>
	)
}
