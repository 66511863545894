export const ColorPool = [
	'#0075A3',
	'#7E4282',
	'#EC4E20',
	'#FF9505',
	'#253C78',
	'#1F1F1F',
	'#AA1155',
	'#074F57',
	'#E7ECEF',
	'#E5F5FC',
];
