import React, { Fragment } from 'react'
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import Img from 'gatsby-image'
import ChartA from "../components/chart-a"
import ChartG from "../components/chart-g"
import ChartI from "../components/chart-i"
import ChartK from "../components/chart-k"
import ChartL from "../components/chart-l"

import Table from '../components/table'
import Iframe from '../components/iframe'
import Images from '../img/Images'
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from 'react-socks'
import Select, { components } from 'react-select'
import CustomSelector from '../components/customSelector'
import { SocialBox } from './socialComponents'
import PymIframe from '../components/pymIframe'

class BodyContentComponent extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			selectField: '',
			charts: {
				A: ChartA,
				G: ChartG,
				I: ChartI,
				K: ChartK,
				L: ChartL,
			}
		}
	}

	getHash = (title) => {
		return title
			.replace(/[^\w\s]/gi, '')
			.toLowerCase()
			.trim()
			.replace(/ /gi, '-')
	}

	render() {
		const { content, social, path } = this.props
		const { charts } = this.state

		const displayContent = content.map((obj, i) => {

			if(!obj) return null;

			let hash;
			if ('sectionTitle' in obj) {
				hash = this.getHash(obj.sectionTitle);
			}

			// Render Key Message
			if ('keyMessage' in obj) {

				hash = this.getHash(obj.keyMessage);
				return <section key={i} id={hash || `content_section_${i}`} className='content_section key-messages'>
					<div>
						<h2 className="key_message">{obj.keyMessage}</h2>
						{social && <SocialBox shareId={hash} path={path}/>}
					</div>
				</section>
			}

			// Render Paragraph
			if ('text' in obj) {
				return <section key={i} id={hash || `content_section_${i}`} className='content_section'>
					{obj.sectionTitle &&
						<div>
							<h3 className="sub_content_title">{obj.sectionTitle}</h3>
							{social && <SocialBox shareId={hash} path={path}/>}
						</div>
					}
					<div
						className="sub_content_body"
						dangerouslySetInnerHTML={{
						__html: obj.text,
						}}
					/>
				</section>
			}

			// Render Image
			if (obj.image) {
				return <section key={i} id={hash || `content_section_${i}`} className='content_section'>
					{obj.sectionTitle && <div>
						<h3 className="sub_content_title">{obj.sectionTitle}</h3>
					</div>}
					<Img fluid={obj.image.fluid} alt="content image" />
				</section>
			}

			// Render Table
			if (obj.table) {
				if(!obj.table) return null;
				return <section
					key={i}
					id={hash || `content_section_${i}`}
					className='content_section'
				>
					{/* <div>
						<h3 className="sub_content_title">{obj.sectionTitle}</h3>
					</div> */}
					<Table
						dataObj={obj.table.file}
					/>
				</section>
			}

		  // Render Chart
			if (obj.chart) {
				// console.log(obj)
				let chartType = obj.chart.chartType.title
				let Chart = charts[chartType]

				if (Chart) {
					let description = '';

					if ('chartDescriptionNode' in obj.chart) {
						description = obj.chart.chartDescriptionNode.childMarkdownRemark.html;
					}

					let format = obj.chart.file.format;
					let size = (obj.chart.file.size/1024).toFixed(2);

					return <section
						key={i}
						id={hash || `content_section_${i}`}
						className='content_section'
					>
						<h4 className="chart-title">{obj.sectionTitle}</h4>
						<Chart
							data={obj.chart.file.csv}
						/>
						{
							obj.chart.file.url &&
							<a
								href={obj.chart.file.url}
								target='blank'
								className={'chart-download'}
							>
								DOWNLOAD CHART DATA {`[${size} kB ${format.toUpperCase()}]`}
							</a>
						}
						{
							description &&
							<div
								className="description"
								dangerouslySetInnerHTML={{
									__html: description
								}}
							/>

						}
					</section>
				}
			}

			// Render Local HTML with iframe
			if (obj.htmlChart) {
					const { sectionTitle, htmlChart  } = obj;
					const displayTitle = sectionTitle && sectionTitle.split('_').join(' ')
					return <section
						key={i}
						id={hash || `content_section_${i}`}
						className='content_section'
					>
					<PymIframe
						id={`${hash}-1`} 
						title = {displayTitle}
						htmlChart = {htmlChart}
					/>
				</section>
			}

			// Render iFrame
			if (obj.link) {
				return <section
					key={i}
					id={hash || `content_section_${i}`}
					className='content_section'
				>
					<Iframe
						data={obj}
					/>
				</section>
			}

		})

		return (
			<Fragment>
				{displayContent}
			</Fragment>
		)
	}
}

BodyContentComponent.propTypes = {
	content: PropTypes.array
}

BodyContentComponent.defaultProps = {
	content: []
}

export default BodyContentComponent
