import React from 'react';
import { Bar } from 'react-chartjs-2';
import Color from 'color';
import Data from '../utils/data';
import { ColorPool } from '../common/color-pool.js';
import { Decimals } from '../common/decimals.js';


const optionsB = {
    responsive: true,
    legend: {
        display: true,
        position: 'bottom'
    },
    scales: {
        yAxes: [{
            display: true
        }],
        xAxes: [{
            display: true,
            scaleLabel: {
                display: true,
                labelString: 'Year'
            }
        }]
    }
};

const optionsA = {
    responsive: true,
    legend: {
        display: true,
        position: 'bottom'
    },
    scales: {
        yAxes: [{
            display: true,
            scaleLabel: {
                display: true,
                labelString: 'Medium bill ($/year)'
            },
            ticks: {
                beginAtZero: true,
                // Include a dollar sign in the ticks
                callback: (value, index, values) => {
                    return `${Decimals(value)}`;
                }
            }
        }],
        xAxes: [{
            display: true,
            scaleLabel: {
                display: true,
                labelString: 'Year'
            }
        }]
    }
};

const ChartA = ({ data }) => {
    let cloneData = [...data];
    cloneData = Data.cleanNumbers(cloneData);
    // Set up Labels
	let labels = cloneData[0].slice(1);
	cloneData = cloneData.slice(1);

    // set up data
    let datasets = [];
    cloneData.forEach((col, i) => {
   		// let currentColor = ColorPool[Math.floor(Math.random() * ColorPool.length)];
   		let currentColor = ColorPool[i];
        let lightColor = Color(currentColor).lighten(0.2);

        let dataset = {
            label: col[0],
            data: Decimals(col.slice(1)),
            backgroundColor: lightColor,
            borderColor: currentColor,
            borderWidth: 1,
            hoverBackgroundColor: lightColor,
            hoverBorderColor: currentColor
        };
        datasets.push(dataset);
    });

    let chart = {
        labels,
        datasets
    };

    return (
        <div>
			<div className="chart-a">
				<div className="first">
					<Bar data={chart} options={optionsA} height={500} />
				</div>
				<div className="second">
					<Bar data={chart} options={optionsB} height={500} />
				</div>
			</div>
		</div>
    );
};

export default ChartA;
