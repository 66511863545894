const Images = {
	hamburger: require('./hamburger.svg'),
	close: require('./hamburger-close.svg'),
	logoBlack: require('./logo-aemc-simple.svg'),
	logoWhite: require('./logo-aemc-simple-inv.svg'),
	topRightWhite: require('./top_right_white.svg'),
	down: require('./icon-down-carrot.svg'),
	mapJurisdiction: require('./graphic-outline-w-dots.svg'),
	search: require('./icon-search.svg'),
	searchBlack: require('./icon-search-black.svg'),
	menu: require('./icon-menu.svg'),
};

export default Images;
