import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import Images from '../img/Images'


const DownloadBlock = ({content}) => {
	const {title, description, file} = content
	const fixSize = file && ((file.size/1000000).toFixed(2))
	return (
		<div className="down-load-item">
			<div className='block-holder'>
				<div className='block-top'>
					<h4>{title}</h4>
					{fixSize && <span>{`[${fixSize}mb ${file.format.toUpperCase()}]`}</span>}
				</div>

				<div className='block-bottom'>
					<p>{description}</p>
					{
						file && <a
							href={file.url.replace('https://www.datocms-assets.com/', 'https://2019.aemc.gov.au/')} target='blank'
						>
							DOWNLOAD
						</a>
					}
				</div>
			</div>
		</div>
	)
}

const DownloadBlocks = (props) => (
	<StaticQuery
		query={graphql`
			query ReportQuery{
				reports: allDatoCmsReport {
					edges {
						node {
							id
							title
							description
							file {
								size
								path
								format
								url
							}

						}
					}
				}
			}
		`}
		render={data => {
			const content = data.reports.edges.map(function(obj, i) {
				if ('file' in obj.node) {
					return <DownloadBlock content={obj.node} key={i}/>
				}
			})
			return (
				<div className='down-load-container'>
					<div className="centre-container">
						<div className="inner grid-ten -centre">
							<div className='intro-block'>
								<h2 className="intro-heading">\ Download this year’s report and data</h2>
							</div>
							<div className='block-table'>
								{content}
							</div>
						</div>
					</div>
				</div>
			)
		}}
	/>
)

export default DownloadBlocks
