import React from 'react';
import Data from '../utils/data';


const Table = ({ dataObj }) => {
	let data = dataObj.csv;
	data = Data.cleanNumbers(data);
	let title = data[2][1];
	let comment = data[4][1];
	let rawData = data.slice(5);

	let format = dataObj.format;
	let size = (dataObj.size/1024).toFixed(2);

	// Build Table Dtat
	let table = `<table>`;

	let colourBool = true;
	let firstCellRowSpan = {
		start: 0,
		height: 0
	};

	// Loop through rows
	rawData.forEach((row, i) => {

		table += `<tr>`;

		// Loop through cells
		row.forEach((elem, j) => {
			rawData[i][j] = rawData[i][j].replace('�', '-');
			/**
			 * Overall logic
			 * 
			 * Merged cells/rows require colspan/rowspan and for the next cell (merged) to be removed
			 * 
			 * The below iterates over each cell in each row and:
			 * 
			 * if next cell in the row is identical, increase colspan of current and skip next cell
			 * 
			 * if cell below is identical, increase rowspan of current
			 * 
			 * if cell above is identical, skip cell 
			 */

			let firstCol = (j == 0) ? `class='col-0'` : '';

			// Check if any column merges
			if (
				i > 0 &&
				rawData[i][j] != '' &&
				rawData[i][j] == rawData[i][j - 1]
			) {
				return;
			} else if (
				j + 1 < row.length &&
				rawData[i][j] != '' &&
				rawData[i][j] == rawData[i][j + 1]
			) {
				let k = 2;

				while (rawData[i][j + k] && rawData[i][j] == rawData[i][j + k]) {
					k += 1;

					if (rawData[i][j - 1 + k] == null) {
						k -= 1;
						break;
					}
				}

				table += `<td colspan='${k}' data-col-${j} data-row-${i} data-color-${colourBool}>${rawData[i][j]}</td>`;
				return;
			}

			// Check if any row merges
			if (
				rawData[i - 1] &&
				rawData[i][j] != '' &&
				rawData[i][j] == rawData[i - 1][j]
			) {
				return;
			} else if (
				rawData[i][j] &&
				rawData[i + 1] &&
				i < rawData.length &&
				rawData[i][j] != '' &&
				rawData[i][j] == rawData[i + 1][j]
			) {
				let k = 2;
				while (rawData[i + k] && rawData[i][j] == rawData[i + k][j]) {
					k += 1;

					if (rawData[i - 1 + k] == null) {
						k -= 1;
						break;
					}
				}

				let content = rawData[i][j];

				if (
					j == 0 &&
					i > firstCellRowSpan.start + firstCellRowSpan.height
				) {
					content = `<div>${content}</div>`;
					firstCellRowSpan = {
						start: j,
						height: k
					};

					colourBool = !colourBool;
				}

				table += `<td rowspan='${k}' data-col-${j} data-row-${i} data-color-${colourBool}>${content}</td>`;
				return;
			}

			if (
				i == 0 &&
				j == 0
			) {
				table += `<td data-zero data-color-${colourBool}></td>`;
			} else {
				table += `<td ${firstCol} data-col-${j} data-row-${i} data-color-${colourBool}>${rawData[i][j]}</td>`;
			}
		});
		table += `</tr>`;
	});
	table += '</table>';

	return (
		<div className={'chart-container'}>
			<h4>{title}</h4>
			<div
				dangerouslySetInnerHTML={{ __html: table }}
				className="table-wrapper"
			/>
			<div className="table-comment">
				{
					dataObj.url &&
					<div className={'table-download'}>
						<a href={dataObj.url} target='blank'>DOWNLOAD TABLE DATA {`[${size} kB ${format.toUpperCase()}]`}</a>
					</div>
				}
				<p className='source'>{comment}</p>
			</div>		
		</div>
	);
};

export default Table;
