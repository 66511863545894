import React from 'react'
import Slider from 'react-slick'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import ContentBlock from '../components/contentBlock'
import Header from '../components/header'

export default (props) => {
	const {data} = props;
	const {seoMetaTags } = data.page;
	return(
		<Layout seo={seoMetaTags} >
			<Header />
			<ContentBlock
				content={data.page}
				location={props.location}
				slug={props.pageContext.slug}
				title={'Feature Article'}
			/>
		</Layout>
	)
}

export const query = graphql`
	query FeatureArticleQuery($slug: String!) {
		page: datoCmsFeatureArticle(slug: { eq: $slug }) {
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			title
			bodyContent {
				... on DatoCmsHeading {
					id
					keyMessage
				}
				... on DatoCmsText {
					id
					sectionTitle
					text
				}
				... on DatoCmsImage {
					sectionTitle
					id
					image {
						url
						format
						size
						fluid(maxWidth: 900, imgixParams: { fm: "jpg", auto: "compress" }) {
							...GatsbyDatoCmsSizes
						}
					}
				}
				... on DatoCmsHtmlChart {
					sectionTitle
					id
					htmlChart {
						csvFile {
							id
							url
						}
						htmlFile {
							id
							url
							path
						}
					}
				}
				... on DatoCmsTable {
					sectionTitle
					id
					table {
						file {
							csv
							url
							format
							size
						}
					}
				}
				... on DatoCmsDatum {
					sectionTitle
					id
					chart {
						chartType {
							title
						}
						file {
							csv
						}
					}
				}
			}
		}
	}
`
